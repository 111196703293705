import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Bill } from "../model/Bill";
import { Helmet } from "react-helmet";
import LineItemComponent from "./LineItem";

const BillComponent = () => {
  const ctx = useContext(AppContext);
  const db = ctx.database;
  const [bill, setBill] = useState<Bill>();
  const [, setBillChanged] = useState(0);
  const urlParams = useParams();

  // Get the user initials of all the users in the bill
  let involvedUserInitials = Object.keys(bill?.lineItems.reduce((acc: {[initials: string]: boolean}, item) => {
    item.splits.forEach((split) => {
      acc[split.userInitials] = true;
    });
    return acc;
  }, {}) || {});

  if (involvedUserInitials.length > 3) {
    involvedUserInitials = involvedUserInitials.slice(0, 3);
  }
  const involvedUsersString = involvedUserInitials.join(", ");
  
  let pageDescription = '';
  
  if (Object.keys(bill?.involvedUsers || {}).length > 0) {
    pageDescription += `Join ${involvedUsersString} and others in splitting`;
  } else {
    pageDescription += 'Join in splitting';
  }
  pageDescription += ` a $${bill?.totalAmount} bill`;
  if (bill?.name) {
    pageDescription += ` from ${bill?.name}`;
  }

  useEffect(() => {
    if (urlParams.receiptId) {
      db?.subscribeToBill(urlParams.receiptId, (bill) => {
        setBill(bill);
        setBillChanged((prev) => prev + 1);
      });
    }
  }, [db, urlParams]);

  const lineItems = bill
    ? bill.lineItems.map((lineItem) => (
        <LineItemComponent key={lineItem.id} lineItem={lineItem} />
      ))
    : [];

  return (
    <>
      <Helmet>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Box sx={{ width: "100%" }}>{lineItems}</Box>
    </>
  );
};

export default BillComponent;
