import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { LineItem, ShareType } from "../model/Bill";
import ExpandMoreButton from "./ExpandMoreButton";
import LineItemSplits from "./LineItemSplits";

type LineItemProps = {
  lineItem: LineItem;
};

const LineItemComponent = ({ lineItem }: LineItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const db = useContext(AppContext).database;

  const [splitMode, setSplitMode] = useState<ShareType>(ShareType.AMOUNT);

  const handleSplitModeUpdate = useCallback((splitMode: ShareType) => {
    setSplitMode((prev) => {
      if (prev !== splitMode && lineItem.splits.length > 0) {
        // Split type will apply to all splits, just use the first
        db?.updateLineItemSplit(lineItem.splits[0], null, splitMode, null);
      }
      return splitMode;
    })
  }, [db, lineItem.splits]);

  const lineItemAmountString = useCallback(() => {
    return lineItem.amount.toLocaleString("en", {
      style: "currency",
      currency: lineItem.currency,
    });
  }, [lineItem.amount, lineItem.currency]);

  const lineItemAmountLeftString = useCallback(() => {
    return (lineItem.amount - lineItem.splitsCalculatedTotal).toLocaleString(
      "en",
      {
        style: "currency",
        currency: lineItem.currency,
      }
    );
  }, [lineItem.amount, lineItem.currency, lineItem.splitsCalculatedTotal]);

  return (
    <Card variant="outlined" sx={{ margin: "8px" }}>
      <CardHeader
        title={lineItem.name}
        subheader={
          <Stack flexDirection="row" sx={{ gap: "6px" }}>
            <Typography variant="body2" color="text.primary">
              {lineItemAmountString()}
            </Typography>
            <Typography
              variant="body2"
              color={lineItem.splitsAreValid ? "text.secondary" : "red"}
            >
              {`(${lineItemAmountLeftString()} left)`}
            </Typography>
          </Stack>
        }
      ></CardHeader>
      <CardContent
        sx={{
          padding: "0px",
          height: "75px",
          overflow: "hidden",
        }}
      >
        <LineItemSplits lineItem={lineItem} />
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMoreButton
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </ExpandMoreButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List component="div" disablePadding>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={splitMode}
                onChange={(event, value) => {
                  handleSplitModeUpdate(value);
                }}
                sx={{ height: "26px" }}
              >
                <ToggleButton size="small" value={ShareType.AMOUNT}>
                  Value
                </ToggleButton>
                <ToggleButton size="small" value={ShareType.SHARES}>
                  Shares
                </ToggleButton>
                <ToggleButton size="small" value={ShareType.PERCENTAGE}>
                  Percent
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default LineItemComponent;
