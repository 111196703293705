import {
  collection,
  doc,
  DocumentData,
  Firestore,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { Bill, LineItem, LineItemSplit } from "../model/Bill";

let activeObservers: Unsubscribe[] = [];
let activeLineItemSplitObservers: { [key: string]: Unsubscribe } = {};

export const observeReceiptFromSnapshot = async (
  db: Firestore,
  billId: string,
  callback: (bill: Bill) => void
) => {
  let bill: Bill = {
    id: "",
    name: "",
    lineItems: [],
    taxLineItems: [],
    subtotalAmount: 0,
    subtotalAmountCurrency: "",
    totalAmount: 0,
    totalAmountCurrency: "",
    involvedUsers: {},
    _ref: "NULL",
  };

  for (const observer of activeObservers) {
    observer();
  }
  activeObservers = [];

  for (const observer of Object.values(activeLineItemSplitObservers)) {
    observer();
  }
  activeLineItemSplitObservers = {};

  // observe bill level changes
  const billObserver = onSnapshot(doc(db, `receipts/${billId}`), (doc) => {
    const billData = doc.data();

    if (billData) {
      bill.id = billData.id;
      bill.name = billData.name;
      bill.involvedUsers = billData.involvedUsers;
      bill.subtotalAmount = billData.subtotalAmount;
      bill.subtotalAmountCurrency = billData.subtotalAmountCurrency;
      bill.totalAmount = billData.totalAmount;
      bill.totalAmountCurrency = billData.totalAmountCurrency;
    }

    console.log("updated bill")
    callback(bill);
  });
  activeObservers.push(billObserver);

  // observe line items
  const lineItemsObserver = onSnapshot(
    collection(db, `receipts/${billId}/lineItems`),
    (querySnapshot) => {
      const lineItemsData: DocumentData[] = querySnapshot.docs.map((doc) => ({...doc.data(), _ref: doc.ref.path}));
      let lineItems: LineItem[] = [];

      const replaceLineItems = bill.lineItems.length !== lineItemsData.length;

      for (const lineItemData of lineItemsData) {
        if (!activeLineItemSplitObservers[lineItemData.id]) {
          // observe line item splits
          const lineItemSplitObserver = onSnapshot(
            collection(
              db,
              `receipts/${billId}/lineItems/${lineItemData.id}/splits`
            ),
            (querySnapshot) => {
              const lineItemSplitsData: DocumentData[] = querySnapshot.docs.map((doc) =>
                ({...doc.data(), _ref: doc.ref.path})
              );
              let lineItemSplits: LineItemSplit[] = [];

              for (const lineItemSplitData of lineItemSplitsData) {
                lineItemSplits.push({
                  id: lineItemSplitData.id,
                  userId: lineItemSplitData.userId,
                  userInitials: lineItemSplitData.userInitials,
                  involved: lineItemSplitData.involved,
                  shareType: lineItemSplitData.shareType,
                  shareValue: lineItemSplitData.shareValue,
                  calculatedAmount: lineItemSplitData.calculatedAmount,
                  calculatedCurrency: lineItemSplitData.calculatedCurrency,
                  _ref: lineItemSplitData._ref
                });
              }

              bill.lineItems.forEach((lineItem) => {
                if (lineItem.id === lineItemData.id) {
                  lineItem.splits = lineItemSplits;
                }
              });

              console.debug("updated line item splits")

              callback(bill);
            }
          );
          activeLineItemSplitObservers[lineItemData.id] = lineItemSplitObserver;
        }

        if (!replaceLineItems) {
          console.debug("merging line items")
          const existingLineItemIdx = bill.lineItems.findIndex(
            (lineItem) => lineItem.id === lineItemData.id
          );
          const existingLineItem = bill.lineItems[existingLineItemIdx];
          existingLineItem.name = lineItemData.name;
          existingLineItem.amount = lineItemData.amount;
          existingLineItem.currency = lineItemData.currency;
          existingLineItem.splitsAreValid = lineItemData.splitsAreValid;
          existingLineItem.splitsCalculatedTotal = lineItemData.splitsCalculatedTotal;
        } else {
          console.debug("replacing line items")
          lineItems.push({
            id: lineItemData.id,
            name: lineItemData.name,
            amount: lineItemData.amount,
            currency: lineItemData.currency,
            splitsAreValid: lineItemData.splitsAreValid,
            splitsCalculatedTotal: lineItemData.splitsCalculatedTotal,
            splits: [],
            _ref: lineItemData._ref
          });
        }
      }

      if (replaceLineItems) {
        bill.lineItems = lineItems;
      }

      callback(bill);
    }
  );
  activeObservers.push(lineItemsObserver);
};
