import { Button } from "@mui/material";
import { getFirestore } from "firebase/firestore";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { firebaseApp } from "../context/FirebaseBootstrap";
import { createFirestoreBill, mexicanBill } from "../mocks/mexicanBill";
import BillList from "./BillList";

const Home = () => {
  const user = useContext(AppContext).user;

  const mockBillCallback = () => {
    createFirestoreBill(mexicanBill, user!.id, getFirestore(firebaseApp))
  };

  return (
    <>
    <Button size="large" title="Create mock bill" onClick={mockBillCallback}>Create Mock Bill</Button>
    <BillList />
    </>
  );
};

export default Home;
