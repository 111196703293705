import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";

const UserRegistration = () => {
  const ctx = useContext(AppContext);
  const db = ctx.database;
  const user = ctx.user;
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [initials, setInitials] = useState("");

  const handleDialogClose = () => {
    db?.updateUser(initials);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (user?.id && !user?.initials) {
      setDialogOpen(true);
    }
  }, [user]);

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          <Typography variant="h6">Enter Initials</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Avatar
              sx={{
                bgcolor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                border: "1px solid rgba(255, 255, 255, 0.12)",
                height: "52px",
                width: "52px",
                fontSize: "26px",
              }}
            >
              {initials}
            </Avatar>
            <TextField
              sx={{ opacity: 0, position: "fixed" }}
              autoFocus
              onChange={(e) => setInitials(e.target.value.toLocaleUpperCase())}
              margin="dense"
              id="name"
              label="Initials"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                // @ts-expect-error
                "data-test-id": "initials-input",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={initials.length !== 2} data-test-id="initials-submit" onClick={handleDialogClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserRegistration;
