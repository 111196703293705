import { IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  // @ts-expect-error
  transition: theme.transitions.create('transform', {
    // @ts-expect-error
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMoreButton