import { createContext } from "react";
import { Bill, LineItem, LineItemSplit, ShareType } from "../model/Bill";

export type API = {
  getAllBills: (callback: ((bills: Partial<Bill>[]) => void)) => void;
  subscribeToBill: (billId: string, callback: ((bill: Bill) => void)) => void;
  updateUser: (initials: string) => Promise<void>;
  addLineItemSplit: (lineItem: LineItem) => Promise<void>;
  updateLineItemSplit: (split: LineItemSplit, shareValue: number | null, shareType: ShareType | null, involved: boolean | null) => Promise<void>;

  uploadReceipt: (file: File) => Promise<void>;
}

type User = {
  id: string;
  initials?: string;
}

export type AppContextType = {
  user: User | null;
  database: API | null;
};

export const AppContext = createContext<AppContextType>({user: null, database: null});

