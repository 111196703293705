import { TextField, InputAdornment } from "@mui/material";
import { useCallback, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { LineItemSplit, ShareType } from "../model/Bill";

type CompactTextFieldProps = {
  disabled?: boolean;
  split: LineItemSplit;
  value: number;
};

const CompactTextField = ({disabled, split, value}: CompactTextFieldProps) => {
  const db = useContext(AppContext).database;

  const adornment = useCallback(() => {
    switch (split.shareType) {
      case ShareType.PERCENTAGE:
        return '%';
      case ShareType.AMOUNT:
        return '$';
      case ShareType.SHARES:
        return 'S';
    }
  }, [split.shareType]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      db?.updateLineItemSplit(split, parseInt(value), null, null);
    },
    [db, split]
  );

  return (
    <TextField
      disabled={disabled}
      value={value}
      onChange={onChange}
      hiddenLabel
      id="outlined-size-small"
      size="small"
      variant="outlined"
      InputProps={{
        type: "number",
        inputMode: "decimal",
        sx: {
          height: "22px",
          width: "60px",
          paddingLeft: '4px',
          '& input': {
            paddingRight: '2px',
            marginLeft: '-6px',
            textAlign: 'right',
          }
        },
        startAdornment: <InputAdornment position="start">{adornment()}</InputAdornment>,
      }}
    />
  );
};

export default CompactTextField;