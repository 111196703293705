import { Card, CardContent, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Bill } from "../model/Bill";

const BillList = () => {
  const ctx = useContext(AppContext);
  const db = ctx.database;
  const user = ctx.user;

  const navigate = useNavigate();
  const toReceipt = (id: string) => {
    navigate(`/receipt/${id}`);
  };

  const [bills, setBills] = useState<Partial<Bill>[]>([]);

  useEffect(() => {
    if (user) {
      db?.getAllBills((bills) => {
        console.log(bills);
        setBills(bills);
      });
    }
  }, [db, user]);

  const billOverviews = bills
    ? bills.map((bill) => (
        <Card
          sx={{ margin: "10px" }}
          data-test-id="bill-overview"
          title={bill.name}
        >
          <CardContent
            onClick={() => {
              toReceipt(bill.id!);
            }}
          >
            <Typography variant="h5" component="div">
              {bill.name}
            </Typography>
          </CardContent>
        </Card>
      ))
    : [];

  return <>{billOverviews}</>;
};

export default BillList;
