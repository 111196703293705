export enum ShareType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  SHARES = 'SHARES',
}

export type LineItemSplit = {
  id: string;
  userId: string;
  userInitials: string;
  involved: boolean;
  shareType: ShareType;
  shareValue: number;
  calculatedAmount: number;
  calculatedCurrency: string;
  _ref: string;
}

export type LineItem = {
  id: string;
  name: string;
  amount: number;
  currency: string;
  splits: LineItemSplit[];
  splitsAreValid: boolean;
  splitsCalculatedTotal: number;
  _ref: string;
}

export type Bill = {
  id: string;
  name: string;
  lineItems: LineItem[];
  taxLineItems: LineItem[];
  subtotalAmount: number;
  subtotalAmountCurrency: string;
  totalAmount: number;
  totalAmountCurrency: string;
  involvedUsers: { [id: string]: boolean }
  _ref: string;
}