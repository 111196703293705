import { AppBar, Button, CssBaseline, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Bill from "./components/Bill";
import Home from "./components/Home";
import UserRegistration from "./components/UserRegistration";
import { FirebaseAppProvider } from "./context/Firebase";
import { darkTheme } from "./Theme";

function App() {
  return (
    <FirebaseAppProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <UserRegistration />
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Splitsies
            </Typography>
            <Button sx={{ color: "#fff" }}>Home</Button>
            <Button sx={{ color: "#fff" }}>About</Button>
          </Toolbar>
        </AppBar>
        <BrowserRouter>
          <Routes>
            <Route path="/receipt" element={<Bill />}>
              <Route path=":receiptId" element={<Bill />} />
            </Route>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />}>
              <Route path=":home" element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </FirebaseAppProvider>
  );
}

export default App;
