import cuid from "cuid";
import { doc, Firestore, setDoc, updateDoc } from "firebase/firestore";
import { Bill } from "../model/Bill";

export const createFirestoreBill = async (bill: Partial<Bill>, uid: string, firestore: Firestore) => {
  bill.id = cuid();
  const lineItems = bill.lineItems || [];
  delete bill.lineItems;

  // @ts-ignore
  bill.involvedUsers = {};
  
  try {
    await setDoc(doc(firestore, 'receipts', bill.id), bill);
  } catch (error) {
    console.log(error)
  }

  for (const lineItem of lineItems) {
    const lineItemSplits = lineItem.splits || [];
    delete (lineItem as any).splits;
    await setDoc(doc(firestore, 'receipts', bill.id, 'lineItems', lineItem.id), lineItem);
    
    for (const split of lineItemSplits) {
      await setDoc(doc(firestore, 'receipts', bill.id, 'lineItems', lineItem.id, 'splits', split.id), split);
    }
  }

  await updateDoc(doc(firestore, 'receipts', bill.id), {involvedUsers: {[uid]: true}});

  return bill.id;
};

export const mexicanBill: Bill = {
  id: '1',
  name: 'Taco Gordo',
  lineItems: [
    {
      id: '1',
      name: "3 Buenaritas",
      amount: 24,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/1"
    },
    {
      id: '2',
      name: "1 Hot Mess",
      amount: 17,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/2"
    },
    {
      id: '3',
      name: "Baja Fish Taco",
      amount: 17,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/3"
    },
    {
      id: '4',
      name: "Quesadilla",
      amount: 12,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/4"
    },
    {
      id: '5',
      name: "Chicken Mole",
      amount: 5,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/5"
    },
  ],
  taxLineItems: [
    {
      id: '1',
      name: "Tax",
      amount: 7.50,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/6"
    },
    {
      id: '2',
      name: "SF Employee Mandate",
      amount: 3.50,
      currency: "USD",
      splits: [],
      splitsAreValid: false,
      splitsCalculatedTotal: 0,
      _ref: "receipts/1/lineItems/7"
    },
  ],
  subtotalAmount: 75,
  subtotalAmountCurrency: "USD",
  totalAmount: 113.50,
  totalAmountCurrency: "USD",
  involvedUsers: {},
  _ref: "receipts/1"
}