import { Avatar, Stack, useTheme } from "@mui/material";
import { useCallback, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { LineItem, LineItemSplit } from "../model/Bill";
import CompactTextField from "./CompactTextField";

type LineItemSplitsProps = {
  lineItem: LineItem;
};

const LineItemSplits = ({ lineItem }: LineItemSplitsProps) => {
  const theme = useTheme();
  const ctx = useContext(AppContext);
  const { database } = ctx;

  const enableLineItemSplitInvolvement = useCallback(
    (split: LineItemSplit) => {
      if (!split.involved) {
        database?.updateLineItemSplit(split, null, null, true);
      }
    }, [database]);

  return (
    <Stack
      key={lineItem.id}
      flexDirection="row"
      alignItems="center"
      sx={{
        height: "100%",
        gap: "6px",
        paddingLeft: "12px",
        paddingBottom: "17px",
        paddingRight: "20px",
        overflowX: "scroll",
        boxSizing: "content-box",
      }}
    >
      {lineItem.splits.map((split) => (
        <Stack
          sx={{ opacity: split.involved ? 1 : 0.4 }}
          onClick={() => enableLineItemSplitInvolvement(split)}
          key={split.id}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              border: "1px solid rgba(255, 255, 255, 0.12)",
            }}
          >
            {split.userInitials}
          </Avatar>
          <CompactTextField
            value={split.shareValue}
            split={split}
            disabled={!split.involved}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default LineItemSplits;
