import { getAuth, signInAnonymously, updateProfile, UserCredential } from "firebase/auth";
import { addDoc, collection, doc, getFirestore, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Bill, LineItem, LineItemSplit, ShareType } from "../model/Bill";
import { API, AppContext } from "./AppContext";
import { firebaseApp } from "./FirebaseBootstrap";
import { observeReceiptFromSnapshot } from "./FirebaseReceiptObserver";

const db = getFirestore(firebaseApp);
const auth = getAuth();

export const FirebaseAppProvider = (props: any) => {
  const [user, setUser] = useState<UserCredential | null>(null);

  const database: API = {
    getAllBills: async (callback: ((bills: Partial<Bill>[]) => void)) => {
      if (!user?.user) {
        return Promise.reject("User not logged in");
      }

      const bills = collection(db, "receipts");
      const q = query(bills, where("involvedUsers." + user.user.uid, "==", true));
      onSnapshot(q, (querySnapshot) => {
        const docs = querySnapshot.docs;
        const results = docs.map((doc) => {
          const data = doc.data();
          return { id: data.id, name: data.name, totalAmount: data.totalAmount };
        });
        callback(results);
      })
    },

    updateUser(initials) {
      if (!user?.user) {
        return Promise.reject("User not logged in");
      }

      return updateProfile(user.user, { displayName: initials });
    },

    subscribeToBill: function (billId: string, callback: (bill: Bill) => void): void {
      observeReceiptFromSnapshot(db, billId, callback);
    },

    addLineItemSplit: async function (lineItem: LineItem): Promise<void> {
      if (!user) {
        return Promise.reject("User not logged in");
      }

      try {
        await addDoc(collection(db, lineItem._ref, 'splits'), { userId: user.user.uid, userInitials: user.user.displayName })
      } catch (err) {
        console.error(err);
      }
    },
    
    updateLineItemSplit: async function (split: LineItemSplit, shareValue: number | null = null, shareType: ShareType | null = null, involved: boolean | null): Promise<void> {
      await updateDoc(doc(db, split._ref), {
        shareValue: shareValue === null ? split.shareValue : shareValue,
        shareType: shareType === null ? split.shareType : shareType,
        involved: involved === null ? split.involved : involved });
    },

    uploadReceipt: function (file: File): Promise<void> {
      if (!user) {
        return Promise.reject("User not signed in");
      }

      return user?.user.getIdToken().then((token) => {
        if (token) {
          var form = new FormData();
          form.append("file", file);

          const request = new XMLHttpRequest();
          request.open("POST", "app/receipt");
          request.setRequestHeader("Authorization", `Bearer ${token}`);
          request.send(form);
        }
      });
    },
  }

  useEffect(() => {
    signInAnonymously(auth)
    .then((user) => {
      setUser(user);
      console.log("signed in", user);
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  const userObj = user?.user.uid ? {
    id: user?.user.uid,
    initials: user?.user.displayName || undefined,
  } : null;

  return (
    <AppContext.Provider value={{user: userObj, database}}>
      {userObj?.id && 
        <div style={{display: 'none'}} data-test-id="user-info" data-user-id={userObj?.id}></div>
      }
      {props.children}
    </AppContext.Provider>
  );
}
