import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

let firebaseConfig;

firebaseConfig = {
  apiKey: "AIzaSyAAKqmS6FDExiNgG4TJsSswSF9tZnCPaxA",
  authDomain: "billme-2133a.firebaseapp.com",
  projectId: "billme-2133a",
  storageBucket: "billme-2133a.appspot.com",
  messagingSenderId: "99708882349",
  appId: "1:99708882349:web:2be5dfc349c8b2df04e524",
  measurementId: "G-BJZ4SEJWKS"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
const db = getFirestore(firebaseApp);
const auth = getAuth();
if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
}